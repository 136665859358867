import React from 'react'
import * as styles from './PageContent.module.scss'

const PageContent = ({children}) => {
  return (
    <div className={styles.wrapper}>
        {children}
    </div>
  )
}

export default PageContent