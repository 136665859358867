import { Link } from 'gatsby';
import React, { useState } from 'react';
import * as styles from './Header.module.scss';
import cx from 'classnames';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

	return (
		<header className={styles.header}>
			<div className={styles.wrapper}>
				<Link to="/" title="Ballistix">
                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.logo} viewBox="0 0 285.8 69.3"><defs><style>{".a{fill:#a1f31e}"}</style></defs><path className="a" d="M24.6 20.8 19.3 28l1.5-9.7L0 1.8l31.6 12.5H56l15-8-5.7 8-10.8 10.6h15L16.3 69.3l32.4-38.9h-9.8l8.8-9.5Z"/><path className="a" d="M28.3 23.5h7.4v18L22.4 57.9l5.9-15.5ZM156.5 19.3V7.5l-9 9.5v2.3ZM147.5 20.3V22l-1.8 8.5 1.8 3.3V50l3.2-3.5 6.7-.3 3.8-6.8-4.7.2v-4.8l-1.7-5 1.7-4.6v-4.9Z"/><path className="a" d="M128.2 46.5h14.4L146 50V33.6l-4 5.7h-5V36l3.1-5.1-3-8.8v-5l-9-9.5v11l3 11-3 4.8ZM157 56.6 169 46.4h8l-1.7-4 10.5-19h-16.5L183.1 0 167 10.1h-6l3.7 4.4-7 13h16.2Z"/><path className="a" d="M104.8 46.4h-3.1l3-4.6v-17l4.5-11.2L101.3 0l16 12h2.9l-4.4 4L111 27v12.2h7.9l3.8-6.1V39l10 17.5-12-10.2ZM95.3 7.5v39l-3.8 9.4-3.9-9.4V24.8L63.8 46.4l-3 .2 4.7 14-13-14Z"/><path className="a" d="m70.4 43.8 8.2-3.4H97l1.4 2.9 1.3-2.9V34l-1.3-3-1.4 3H80.8ZM230 46.6l-4.8 14 13.2-14h2.9l5.4-8 36.7 30.7L253 30.4l5.4-9.6h2.9l5.3 7.3-1.6-9.7 20.9-16.6-31.6 12.5h-3.4l-4.4 7.6-4.6-7.6h-10.2l-2.6 6.4 4-2.6 7.8 12.3ZM203 17.1l-10.6-9.6v39l3.9 9.4 3.9-9.4V19.2Z"/><path className="a" d="m213.6 13.6-4.2 6.4h-20l-1.3 3-1.3-3v-6.3l1.3-3 1.3 3h17.9ZM227 18l-6.3 15 4.6 13.4-9.5-.2H210l4.4-3.7-3.6-9.4 6.8-15.2ZM218.2 16.5l1-2.2-5.6-8 14.9 8-1 2.2Z"/></svg>
                </Link>
                <button className={styles.menuButton} aria-label="Expand menu" onClick={() => setIsOpen(!isOpen)}>                
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width={32} height={32}><path fill="#fff" d="M 4 7 L 4 9 L 28 9 L 28 7 Z M 4 15 L 4 17 L 28 17 L 28 15 Z M 4 23 L 4 25 L 28 25 L 28 23 Z"/></svg>
                </button>
				<nav className={cx([styles.nav, {[styles.navVisible]: isOpen}])}>
					<Link to='/' activeClassName={styles.active}>Home</Link>
					<Link to='/releases/' activeClassName={styles.active}>Discography</Link>
					<Link to='/songs/' activeClassName={styles.active}>Lyrics</Link>
				</nav>
			</div>
		</header>
	)
}

export default Header;