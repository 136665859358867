import { Link } from 'gatsby';
import React from 'react'
import * as styles from './SongList.module.scss'

type Props = {
    songs: any;
}

const SongList = ({ songs }: Props) => (
    <ul className={styles.songs}>
        {songs.map(({ node: track }) => (
            <li key={track.title}>
                <Link to={`/songs/${track.slug.current}/`} className={styles.song}>{track.title}</Link>
            </li>
        ))}
    </ul>
)

export default SongList
