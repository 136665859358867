import React from 'react'
import * as styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <div className={styles.wrapper}>
            <div className={styles.copyright}>
                Copyright © {new Date().getFullYear()} BALLISTIX. All Rights Reserved.
            </div>
        </div>
    </footer>
  )
}

export default Footer