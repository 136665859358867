import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';
import SongList from '../components/SongList';

const SongsPage = (props) => {
	return (
		<Layout>
            <PageContent>
                <h1>Songs</h1>
                <SongList songs={props.data.allSanityTrack.edges} />
            </PageContent>
		</Layout>
	)
}

export default SongsPage;

export const pageQuery = graphql`
	query {
		allSanityTrack(sort: {fields: title, order: ASC}) {
			edges {
				node {
					title
					slug {
						current
					}
				}
			}
		}
	}
`
