import React from 'react';
import Footer from './Footer';
import Header from './Header';
import * as styles from './Layout.module.scss';

const Layout = ({ children }) => {
	return (
		<div className={styles.wrapper}>
            <Header />
            <main className={styles.main}>
    			{children}
            </main>
            <Footer />
		</div>
	);
};

export default Layout;
